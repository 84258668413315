<template>
  <div class="myhome" :style="'height:' + (screeHeight-50) + 'px;'">
    <!-- 头部 -->
    <div class="head">
      <van-cell :border="false" title-class="nickname van-ellipsis">
        <template #icon>
          <van-image
            round
            width="60"
            height="60"
            :src="isLogin && userinfo.avatar ? userinfo.avatar : avatar"
            lazy-load
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <van-image
            v-if="userinfo.hehuoren == 1"
            style="width:68px;position: absolute;top: 4px;"
            :src="hhr"
          ></van-image>
        </template>
        <!-- 已经登陆显示内容 -->
        <template #title>
          <div
            v-show="isLogin"
            style="overflow: hidden;display: flex;-webkit-box-align: center; -webkit-align-items: center; align-items: center;"
          >
            <span class="nickname_text van-ellipsis">{{userinfo.nickname?userinfo.nickname:nickname}}</span>
            <van-tag
              round
              color="#FED1A7"
              text-color="#754A20"
            >{{userinfo.levelname?userinfo.levelname:'普通会员'}}</van-tag>
            <div v-if="isLogin" style="flex: 1; text-align: right; margin-left: 10px; padding-top: 8px;">
              <van-icon @click="setMessage" :badge="userinfo.unreadynum ? userinfo.unreadynum : ''" class="right_icon" name="chat-o"></van-icon>
              <van-icon @click="settings" class="right_icon" name="setting-o"></van-icon>
            </div>
          </div>
          <van-cell v-show="!isLogin" title="登陆/注册" is-link @click="lijidenglu"></van-cell>
        </template>
        <template #label v-if="isLogin">
          <van-cell @click="agentCodeCopy">
            <template #icon>
              <div style="margin-right: 10px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                {{merchname}}
              </div>
            </template>
            <template #title>
              邀请码：{{ isLogin && userinfo.agent_code ? userinfo.agent_code : "" }}
              <van-tag plain>复制</van-tag>
            </template>
          </van-cell>
        </template>
      </van-cell>
      <van-grid :border="false" :column-num="5">
        <van-grid-item text="预计收益" :to="isLogin ? {path:'/commissionMoney',query: {ishehuoren: userinfo['level1_white'],shareid: shareid, merchid: merchid}} :''">
          <template #icon>{{ isLogin ? parseFloat(userinfo.commissionMoney) : "0" }}</template>
        </van-grid-item>
        <van-grid-item text="余额" @click="chongzhi('credit2')">
          <template #icon>{{ isLogin ? parseFloat(userinfo.credit2) : "0" }}</template>
        </van-grid-item>
        <van-grid-item text="积分">
          <template #icon>{{ isLogin ? parseFloat(userinfo.credit1) : "0" }}</template>
        </van-grid-item>
        <van-grid-item>
          <template #text>
            <span class="van-grid-item-text">本月销售</span>
          </template>
          <template #icon>{{ isLogin && userinfo.salses ? userinfo.salses + "元" : "0" }}</template>
        </van-grid-item>
        <van-grid-item text="收藏夹" @click="favorite">
          <template #icon>{{ isLogin && userinfo.favoriteNum ? userinfo.favoriteNum : "0" }}</template>
        </van-grid-item>
      </van-grid>
      <div class="shengji" v-if="showOPenShops">
        <!-- userinfo.level1_white == 0 || (userinfo.merch_reg && userinfo.merch_reg.lianmeng_status == 1) || userinfo.invite_shop == 0 ? false : true -->
        <van-cell :border="false">
          <template #title>我要开店</template>
          <template #default>开通联盟店铺，轻松赚钱</template>
          <template #right-icon>
            <van-tag @click="mshegnji" round>立即开店</van-tag>
          </template>
        </van-cell>
      </div>
    </div>
    <!-- 升级等级 -->
    <div class="level_info" v-if="(userinfo.uplevelarr || userinfo.savelevelarr) && (shopinfo.autoupLevel == 1 || shopinfo.id == userinfo.merchid)">
      <van-cell center>
        <template #title>
          <div class="van-hairline--right">
            <div class="level_title">
              当前等级
            </div>
            <div class="level_text">
              <van-tag color="#FED1A7" round text-color="#754A20">{{userinfo.levelname?userinfo.levelname:'普通会员'}}</van-tag>
            </div>
          </div>
        </template>
        <template #default>
          <div>
            <div class="title_text" v-if="(userinfo.level != 5 && userinfo.level1_white == 1) || (userinfo.level!=14 && userinfo.level1_white == 0)">
              完成任务可晋升为
              <span>{{userinfo.uplevelarr && userinfo.uplevelarr.levelname ? userinfo.uplevelarr.levelname : ""}}</span>
            </div>
            <div class="title_text" v-if="(userinfo.level == 5 && userinfo.level1_white == 1) || (userinfo.level==14 && userinfo.level1_white == 0)">
              您已是最高等级
            </div>
            <div v-if="userinfo.uplevelarr && userinfo.uplevelarr.point && userinfo.uplevelarr.point.share">
              <van-cell center :border="false">
                <template #title>
                  <div class="icon_text">分享人数</div>
                </template>
                <template #default>
                  <van-progress color="#ee0a24" :percentage="userinfo.fansNum >= userinfo.uplevelarr.point.share ? 100 : (userinfo.fansNum/userinfo.uplevelarr.point.share)*100" :show-pivot="false" />
                </template>
                <template #right-icon>
                  <div class="right_icon_text">{{userinfo.fansNum}}/{{userinfo.uplevelarr.point.share}}</div>
                </template>
              </van-cell>
            </div>
            <div v-if="userinfo.uplevelarr && userinfo.uplevelarr.point && userinfo.uplevelarr.point.money">
              <van-cell>
                <template #title>
                  <div class="icon_text">团队业绩</div>
                </template>
                <template #default>
                  <van-progress color="#ee0a24" :percentage="userinfo.salseMoney >= userinfo.uplevelarr.point.money ? 100 : (userinfo.salseMoney/userinfo.uplevelarr.point.money)*100" :show-pivot="false" />
                </template>
                <template #right-icon>
                  <div class="right_icon_text">{{userinfo.salseMoney}}/{{userinfo.uplevelarr.point.money}}</div>
                </template>
              </van-cell>
            </div>
          </div>
        </template>
      </van-cell>
      <van-cell center v-if="!((userinfo.level==1 && userinfo.check_level==0) || (userinfo.level==6 && userinfo.teji_white==1))">
        <template #title>
          <div class="title_text">
            保级任务
          </div>
        </template>
        <template #default>
          <div v-if="userinfo.savelevelarr && userinfo.savelevelarr.point && ((userinfo.savelevelarr.point.share && userinfo.fansNum < userinfo.savelevelarr.point.share) || (userinfo.savelevelarr.point.money && userinfo.salseMoney < userinfo.savelevelarr.point.money))">
            <div v-if="userinfo.savelevelarr && userinfo.savelevelarr.point && userinfo.savelevelarr.point.share && userinfo.fansNum < userinfo.savelevelarr.point.share">
              <van-cell center :border="false">
                <template #title>
                  <div class="icon_text">分享人数</div>
                </template>
                <template #default>
                  <van-progress color="#ee0a24" :percentage="userinfo.fansNum >= userinfo.savelevelarr.point.share ? 100 : (userinfo.fansNum/userinfo.savelevelarr.point.share)*100" :show-pivot="false" />
                </template>
                <template #right-icon>
                  <div class="right_icon_text">{{userinfo.fansNum}}/{{userinfo.savelevelarr.point.share}}</div>
                </template>
              </van-cell>
            </div>
            <div v-if="userinfo.savelevelarr && userinfo.savelevelarr.point && userinfo.savelevelarr.point.money && userinfo.salseMoney < userinfo.savelevelarr.point.money">
              <van-cell center :border="false">
                <template #title>
                  <div class="icon_text">团队业绩</div>
                </template>
                <template #default>
                  <van-progress color="#ee0a24" :percentage="userinfo.salseMoney >= userinfo.savelevelarr.point.money ? 100 : (userinfo.salseMoney/userinfo.savelevelarr.point.money)*100" :show-pivot="false" />
                </template>
                <template #right-icon>
                  <div class="right_icon_text">{{userinfo.salseMoney}}/{{userinfo.savelevelarr.point.money}}</div>
                </template>
              </van-cell>
            </div>
          </div>
          <div  v-if="userinfo.savelevelarr && userinfo.savelevelarr.point && ((userinfo.savelevelarr.point.share && userinfo.fansNum < userinfo.savelevelarr.point.share) || (userinfo.savelevelarr.point.money && userinfo.salseMoney < userinfo.savelevelarr.point.money)) ? false : true">保级任务已完成</div>
        </template>
      </van-cell>
      <van-cell center v-if="((userinfo.level==1 && userinfo.check_level==0) || (userinfo.level==6 && userinfo.teji_white==1))" class="miankaohe">
        <template #title>
          <div v-if="(userinfo.level==1 && userinfo.check_level==0)">
            免考核顶级
          </div>
          <div v-if="(userinfo.level==6 && userinfo.teji_white==1)">
            免考核特级
          </div>
        </template>
      </van-cell>
      <van-cell center :border="false">
        <template #icon>
          <div style="margin-right: 10px;font-size: 12px;">闪团团业绩</div>
        </template>
        <template #title>
          <div style="font-size: 14px;">&yen;{{sttOrderPrice ? sttOrderPrice : 0}}</div>
        </template>
      </van-cell>
    </div>
    <!-- 按钮列表 -->
    <div class="menu_list">
      <van-grid :border="false" use-slot :column-num="5" :style="userinfo.uplevelarr?'':'border-radius: 0 0 10px 10px;'">
        <van-grid-item
          @click="myshops"
          icon="shop"
          text="店铺管理"
          style="color: #F8782D;"
          v-if="userinfo.level1_white == 1 || userinfo.shopid > 0"
        />
        <van-grid-item
          @click="shareMinQr"
          icon="qr"
          text="推广码"
          style="color: #EE0A24;"
          v-if="isLogin"
        />
        <van-grid-item
          @click="orderList(1)"
          icon="gold-coin"
          text="待付款"
          :badge="userinfo.paymentNum > 0 ? userinfo.paymentNum : ''" 
        />
        <van-grid-item
          @click="orderList(3)"
          icon="gift"
          text="待发货"
          :badge="userinfo.sendorderNum > 0 ? userinfo.sendorderNum : ''"
        />
        <van-grid-item
          @click="orderList(4)"
          icon="logistics"
          text="待收货"
          :badge="userinfo.receiptorderNum > 0 ? userinfo.receiptorderNum : ''"
        />
        <van-grid-item
          @click="orderList(0)"
          icon="todo-list"
          text="全部订单"
        />
        <van-grid-item
          @click="orderList(7)"
          icon="manager"
          text="我的售后"
          :badge="userinfo.afterorderNum > 0 ? userinfo.afterorderNum : ''"
        />
        <van-grid-item
          @click="orderList(6)"
          icon="warning"
          text="需退款"
          :badge="userinfo.rejectorderNum > 0 ? userinfo.rejectorderNum : ''"
        />
        <van-grid-item
          v-if="false"
          icon="question"
          text="常见问题"
        />
        <van-grid-item
          v-if="false"
          icon="column"
          text="商学院"
        />
        <van-grid-item
          @click="seeOrder"
          icon="qr"
          text="查单码"
        />
        <van-grid-item
          @click="fxzhongxin"
          icon="bill"
          text="分销中心"
        />
        <van-grid-item
          v-if="userinfo.kefu_wx"
          icon="service"
          text="专属客服"
          @click="yulankf"
        />
        <van-grid-item
          icon="qr"
          text="专属素材号"
          style="color: #DF97F8;"
          @click="onShowsch"
        />
        <van-grid-item
          @click="topage(2)"
          icon="location"
          text="收货地址"
        />
        <van-grid-item
          v-if="userinfo.merch_reg && userinfo.merch_reg.gonghuo_status == 1 && !userinfo.merch_reg.gonghuo_bohui && !userinfo.merch_reg.gonghuo_qianyue ? false : true"
          icon="records"
          @click="applyGonghuo"
          text="供货申请"
          :badge="userinfo.merch_reg && (userinfo.merch_reg.gonghuo_status == -1 || userinfo.merch_reg.gonghuo_bohui || userinfo.merch_reg.gonghuo_qianyue) ? '!' : ''"
        />
        <van-grid-item
          v-if="userinfo.merchtype && (userinfo.merchtype == 1 || userinfo.merchtype == 12) ? true : false"
          icon="qr-invalid"
          @click="shopQr()"
          text="店铺码"
        />
        <van-grid-item
          icon="todo-list-o"
          @click="goOldshop()"
          text="返回旧版"
        />
        <van-grid-item
          v-if="merchid==0"
          icon="records"
          @click="complaint"
          text="投诉建议"
        />
        <van-grid-item
          v-if="merchid==0"
          icon="orders-o"
          @click="cooperation"
          text="商务合作"
        />
        <van-grid-item
          :badge="userinfo.recharge_fail == 2 ? '!' : ''"
          icon="balance-o"
          @click="chongzhi('credit2')"
          text="余额充值"
        />
        <van-grid-item
          v-if="userinfo.gonghuo > 0 && iswx"
          :badge="userinfo.serviceorderNum > 0 ? userinfo.serviceorderNum : ''"
          icon="refund-o"
          text="售后处理"
        >
          <div id="shouhou">

          </div>
        </van-grid-item>
        <van-grid-item
          icon="add-o"
          @click="addAccount"
          text="管理账号"
          v-if="userinfo.level1_white == 1"
        />
        <van-grid-item
          v-if="userinfo.is_showJishi == 1 && iswx"
        >
          <div id="yundianjishi">

          </div>
        </van-grid-item>
        <van-grid-item
          icon="goods-collect-o"
          text="督导产品"
          v-if="userinfo.showDudao == 1"
          :to="{ name: 'dudaoBrandList', query: { shareid: shareid, merchid: merchid, _t: 3 } }"
        />
        <van-grid-item
          icon="discount"
          text="活动列表"
          :to="{ name: 'activityList', query: { my: 1, shareid: shareid, merchid: merchid, _t: 3 } }"
        />
        <van-grid-item
          icon="goods-collect-o"
          text="领取礼品"
          :to="{ path: 'zengpinList' }"
        />
        <van-grid-item
          v-if="userinfo.id == 1055 || userinfo.id == 69041 || userinfo.id == 1"
          icon="chart-trending-o"
          text="店铺排行"
          :to="{ path: 'shop_list' }"
        />
        <van-grid-item
          icon="label-o"
          text="账号列表"
          :to="{ path: 'Hebing' }"
        />
      </van-grid>
    </div>
    <!-- 广告 -->
    <div class="ad" @click="goOldshop()" style="display:none;">
      <van-image :src="adimg" lazy-load>
        <template v-slot:loading>
          <van-loading type="spinner" size="20" />
        </template>
      </van-image>
    </div>
    <!-- 横版跳页按钮 -->
    <div class="tiaozhuan_list">
      <van-cell-group :border="false">
        <van-cell v-if="false" icon="chat" title="我的消息" @click="topage(1)">
          <template #right-icon>
            <van-icon name="arrow"></van-icon>
          </template>
        </van-cell>
        <van-cell v-if="false" icon="location" title="收货地址" @click="topage(2)">
          <template #right-icon>
            <van-icon name="arrow"></van-icon>
          </template>
        </van-cell>
        <van-cell v-if="false" icon="gold-coin" title="充值记录" @click="topage(3)">
          <template #right-icon>
            <van-icon name="arrow"></van-icon>
          </template>
        </van-cell>
        <van-cell v-if="false" icon="eye" title="浏览记录" @click="topage(4)">
          <template #right-icon>
            <van-icon name="arrow"></van-icon>
          </template>
        </van-cell>
        <van-cell v-if="false" icon="star" title="我的收藏" @click="topage(5)">
          <template #right-icon>
            <van-icon name="arrow"></van-icon>
          </template>
        </van-cell>
        <van-cell v-if="false" icon="comment" title="工单反馈" @click="topage(6)">
          <template #right-icon>
            <van-icon name="arrow"></van-icon>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div class="btnlogin">
      <van-button v-show="isLogin" @click="logout" type="danger" round block>退出登录</van-button>
    </div>
    <!-- 加载中提示 -->
    <div id="loading" v-show="loadshow">
      <van-loading size="24px" type="spinner" vertical>加载中...</van-loading>
    </div>

    <!-- 专属素材号弹框 -->
    <van-dialog
      title="专属素材号"
      v-model="showsucai"
    >
      <div class="sucai_show">
        <van-row>
          <van-col :span="userinfo.level1_white == 1 ? 12 : 24">
            <div class="sucai_show_title">跟圈素材号</div>
            <div class="sucai_show_tishi">(此号用于日常跟朋友圈)</div>
            <van-image
              src="https://img.midbest.cn/uploads/sucai/sucai_genquan.png"
              fit="cover"
              width="100"
              height="100"
              style="display: block; margin: 0 auto;"
              lazy-load
              @click="yulansch('https://img.midbest.cn/uploads/sucai/sucai_genquan.png')"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </van-col>
          <van-col v-if="userinfo.level1_white == 1" span="12">
            <div class="sucai_show_title">辅助群发素材号</div>
            <div class="sucai_show_tishi">(此号帮助大家在群里发素材)</div>
            <van-image
              src="https://img.midbest.cn/uploads/sucai/sucai_qunfa.png"
              fit="cover"
              width="100"
              height="100"
              style="display: block; margin: 0 auto;"
              lazy-load
              @click="yulansch('https://img.midbest.cn/uploads/sucai/sucai_qunfa.png')"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </van-col>
        </van-row>
      </div>
    </van-dialog>
  </div>
</template>

<script>

import { ImagePreview } from 'vant';
export default {
  name: "Myhome",
  filters: {
    currencyFormat: function(num) {
      if (num === 0) {
        return "0.00";
      } else {
        num = num + "";
        if (num.length == 1) {
          num = "00" + num;
        }
        if (num.length == 2) {
          num = "0" + num;
        }
        let start = 0;
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    dateFormat: function(value) {
      let date = null;
      let y = null;
      date = new Date(value);
      y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      /*
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      */
      return y + "/" + MM + "/" + d; // + ' ' + h + ':' + m + ':' + s;
    }
  },
  data() {
    return {
      avatar: require("../assets/head.png"), // 默认用户头像
      hhr: require("../assets/hhr.png"), // 皇冠
      nickname: "", // 用户昵称
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      adimg: require("../assets/adOldurl.jpg"), // 本地广告图片
      isLogin: localStorage.getItem("DK_ISLOGIN")
        ? localStorage.getItem("DK_ISLOGIN")
        : false,
      userinfo: {},
      showLogout: false, // 默认不显示退出登录按钮
      openid: localStorage.getItem("DK_OPENID")
        ? localStorage.getItem("DK_OPENID")
        : false, // 获取用户openid
      code: '', // 用来获取用户授权后的code值
      iswx: false, // 是否在微信中
      shareid: 0,
      merchid: 0,
      loginshow: false,
      btnDisabled: true,
      timeData: {
        seconds: 0
      },
      showTxt: true,
      time: -1,
      tel: "",
      sms: "",
      agentInfo: {},
      agentCode: "",
      loadshow: false,
      merchname : "",
      showOPenShops: false,
      shopinfo: {},
      miniprogram: "",
      token: localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : '',
      wxConfig: {
        debug: false, // 开启调试模式 true false
        appId: "wxc465682b705715e5",
        jsApiList: [
          "wx-open-launch-weapp"
        ], // 必填，需要使用的JS接口列表
        timestamp: 1616831396, // 必填，生成签名的时间戳
        nonceStr: "CM9jdUlEokV3kzc4", // 必填，生成签名的随机串
        signature: "8f512cc27c4f3edc17f579067f2da3a7837ad52b", // 必填，签名
        openTagList: [
          "wx-open-launch-weapp"
        ]
      },
      sttOrderPrice: 0, // 闪团团业绩
      showsucai: false, // 是否显示素材号
    };
  },
  mounted() {
    // 页面第一次加载调用的方法
    if (!window.isWeixin()) {
      this.showLogout = true; // 不在微信端打开
      this.iswx = false; // 不在微信端打开
    }else {
      this.iswx = true;
    }
    this.isLogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (!this.isLogin) {
      this.userinfo = {};
    }
    const OPENID = localStorage.getItem("DK_OPENID") ? true : false;
    if (!OPENID && this.iswx) {
      // 用户授权后获取code值
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        this.getwxlog(this.code);
        return false;
      }
    }else{
      if (this.iswx) {
        this.code = this.$route.query.code ? this.$route.query.code : null;
        if (this.code) {
          this.$router.replace({ name: "Myhome", query: { shareid: this.$route.query.shareid, merchid: this.$route.query.merchid, _t: 3 } })
        }
      }
    }
    
  },
  activated() {
    this.getsuofen();
    if (localStorage.getItem("home")) {
      localStorage.removeItem("home")
      this.$router.go(0);
    }
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "会员中心");

    this.isLogin = localStorage.getItem("DK_ISLOGIN") ? true : false;
    if (!window.isWeixin()) {
      this.showLogout = true; // 不在微信端打开
    }else{
      const OPENID = localStorage.getItem("DK_OPENID");
      if (!OPENID) {
        // if (this.shareid==69041) {
        //   this.getwxinfo();
        // }
      }else{
        const MEMBERID = localStorage.getItem("DK_MEMBERID");
        if (MEMBERID) {
          this.updateOpneUser(MEMBERID, OPENID);
        }
      }
    }
    
    setTimeout(() => {
      this.merchname = localStorage.getItem("DK_SHOPNAME")?localStorage.getItem("DK_SHOPNAME"):"";
    }, 1000);
    if (!this.isLogin) {
      this.userinfo = {};
    }

    /* 绑定用户和子商户会员关系 */
    this.setmerchMber();
    // 页面每次显示的时候调用的方法
    this.init();
  },
  methods: {
    init() {
      let _that = null;
      _that = this;
      _that.getinfor(this.merchid);
      _that.getSttOrder();
    },

    handleLaunchFn(e) {
      console.log(e, "handleLaunchFn");
    },

    handleErrorFn(e) {
      console.log(e, "handleErrorFn");
    },

    handRead(e) {
      console.log(e, "handRead");
    },
    // 获取闪团团订单业绩
    getSttOrder() {
      let that = null;
      that = this;
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID") ? localStorage.getItem("DK_UID") : "";
      that.$axios
        .post(that.$store.state.domain + 'web/user/getSttOrder')
        .then(res => {
          console.log(res);
          if (res.code == 100000) {
            that.sttOrderPrice = res.data;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    
    // 分享
    shareMinQr() {
      let that = null;
      that = this;
      if (that.miniprogram && that.miniprogram.length > 0) {
        ImagePreview([
          that.miniprogram
        ]);
        return false;
      }
      that.$toast({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      });

      if (that.userinfo.id<=0) {
        this.$toast("为获取到用户信息,请重新登录");
        return false;
      }
      if (!that.shopinfo.merchname || that.shopinfo.merchname.length<=0) {
        this.$toast("为获取到用户信息,请重新登录");
        return false;
      }
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "web/goodsposter/getPromoteQr",
          that.$qs.stringify({
            "shareid": that.userinfo.id, // 会员id
            "merchid" : that.shopinfo.id, // 商户id
            "page": "pages/index/index", // 二维码地址id
            "appid": "wx6a6b5e6fbb588e66"
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          if (res.data.code == 100000) {
            that.miniprogram = res.data.data;
            ImagePreview([
              res.data.data
            ]);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    peizhiWxConfig() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      /* 获取wx初始化配置 */
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_sign",
          _that.$qs.stringify({
            url: encodeURIComponent(window.location.href)
          })
        )
        .then(function (response) {
          _that.wxConfig.timestamp = response.data.timestamp;
          _that.wxConfig.nonceStr = response.data.nonceStr;
          _that.wxConfig.signature = response.data.signature;
          window.wx.config(_that.wxConfig); // wx初始化配置
          setTimeout(() => {
            const jishi = `<wx-open-launch-weapp style="width: `+((_that.screenWidth-20)*0.2-16)+`px; height: 100%; overflow: hidden;" id="launch-btn" username="gh_47ca22f7d117" path="/ershou/jishi/jishi?token=`+_that.token+`" @ready="handRead" @launch="handleLaunchFn" @error="handleErrorFn" >
              <template>
                <div style="width: 100% ;height: 100%; border: none;background: #fff;padding: 0; overflow: hidden; text-align: center;">
                  <img style="display: block;height: 28px; width: 28px;margin: 0 auto;" src="https://img.midbest.cn/uploads/image/member/2021/03/27/9a0710fbebb9e288b9d46518c19380f7.png" />
                  <div style="margin-top: 8px; width: 100%; color: #646566; font-size: 12px; line-height: 1.5;">云店集市</div>
                </div>
              </template>
            </wx-open-launch-weapp>`;
            document.getElementById("yundianjishi").innerHTML = jishi;

            const gonghuo = `<wx-open-launch-weapp style="width: `+((_that.screenWidth-20)*0.2-16)+`px; height: 100%; overflow: hidden;" id="launch-btn" username="gh_73f6500c72eb" path="/pages/index/index?token=`+_that.token+`" @ready="handRead" @launch="handleLaunchFn" @error="handleErrorFn" >
              <template>
                <div style="width: 100% ;height: 100%; border: none;background: #fff;padding: 0; overflow: hidden; text-align: center;">
                  <img style="display: block;height: 28px; width: 28px;margin: 0 auto;" src="https://img.midbest.cn/uploads/image/member/2021/03/29/c515e5c16a46f7300dfb87fe50bb4230.png" />
                  <div style="margin-top: 8px; width: 100%; color: #646566; font-size: 12px; line-height: 1.5;">售后处理</div>
                </div>
              </template>
            </wx-open-launch-weapp>`;
            document.getElementById("shouhou").innerHTML = gonghuo;

          }, 500);
        })
        .catch(function (error) {
          console.log(error, "errors");
        });
    },

    gotoXcx() {
      const xcx = document.getElementById("toxcx");
      const zhezhao = document.getElementById("zhezhao");
      
      xcx.style.display = "block";
      zhezhao.style.display = "block";
      setTimeout(() => {
        const btn = document.getElementById("launch-btn");
        btn.addEventListener('launch', function (e) {
          console.log('success');
        });

        btn.addEventListener('error', function (e) {
          console.log('fail', e.detail);
        });
      }, 500);
      
    },

    txcxhiden() {
      const xcx = document.getElementById("toxcx");
      const zhezhao = document.getElementById("zhezhao");
      
      xcx.style.display = "none";
      zhezhao.style.display = "none";
    },
    
    // 获取会员信息
    getinfor(mid) {
      let _that = null;
      _that = this;
      const jwtStr = localStorage.getItem("DK_UID")
        ? localStorage.getItem("DK_UID")
        : ""; // 公钥
      _that.$axios.defaults.headers.common['Authorization'] = jwtStr;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/getinfor",
          _that.$qs.stringify({
            merchid: mid
          })
        )
        .then(function(response) {
          _that.isLogin = localStorage.getItem("DK_ISLOGIN")
            ? localStorage.getItem("DK_ISLOGIN")
            : false;
          _that.strId = localStorage.getItem("DK_STRID")
          if (response.data.strId) {
            localStorage.setItem("DK_STRID",response.data.strId)
          }
          if (response.data.id) {
            localStorage.setItem("DK_MEMBERID", response.data.id)
          }
          if (_that.isLogin && response.data) {
            _that.userinfo = response.data;
          }

          if (window.isWeixin()) {
            _that.peizhiWxConfig(); // 配置微信分享
          }

          // _that.showOPenShops = (_that.userinfo.level1_white == 1 && !(_that.userinfo.merch_reg && _that.userinfo.merch_reg.lianmeng_status == 1)) || (_that.userinfo.level1_white == 0 && _that.userinfo.invite_shop == 1) ? true : false;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    validator(val) {
      return /1\d{10}/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },

    // 更新数据
    updateOpneUser(uid,openid) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/pay/setopenid",
          _that.$qs.stringify({
            openid: openid,
            mid: uid
          })
        )
        .then(response => {
          console.log(response);
          if (this.iswx) {
            this.code = this.$route.query.code ? this.$route.query.code : null;
            if (this.code) {
              this.$router.replace({ name: "Myhome", query: { shareid: this.$route.query.shareid, merchid: this.$route.query.merchid, _t: 3 } })
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
    },

    // 获取用户微信信息
    getwxlog(code) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            localStorage.setItem("DK_OPENID", response.data); // 获得用户的openid
            _that.$axios
              .post(
                _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
                _that.$qs.stringify({
                  openid: response.data
                })
              )
              .then(res => {
                if (res.data && res.data.headimgurl && res.data.openid == response.data) {
                  localStorage.setItem("DK_WXINFO", _that.$qs.stringify(res.data));
                }
              })
              .catch(error => {
                console.log(error);
              })
            if(localStorage.getItem("DK_ISLOGIN")) {
              setTimeout(() => {
                /* 保存openID */
                _that.saveOpenid();
              }, 200)
            }else {
              _that.lijidenglu(); // 跳转登陆页面
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    /* 判断是否在微信中打开，如在微信中打开获取openid或用户信息 */
    getwxinfo() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      let openid = null;
      openid = localStorage.getItem("DK_OPENID");
      if (!window.isWeixin()) {
        openid = true; // 在H5页面中打开
        this.iswx = false; 
      }

      /* 在微信中打开页面 */
      if (!openid && this.iswx) { 
        this.iswx = true; 
        const wxAuthUrl2 = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl2; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },

    // 查询是否被锁粉
    getsuofen() {
      let _that = null;
      _that = this;
      let merchid = 0;
      merchid = this.$route.query.merchid;
      console.log(merchid, "home_merchid");
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/get_shop_info",
          _that.$qs.stringify({
            id: merchid
          })
        )
        .then(res => {
          console.log(res);
          console.log(res.data.data.merchid, merchid, "dddddd");
          if (res.data.code == 100000) {
            _that.shopinfo = res.data.data;
            if (merchid != res.data.data.id) {
              
              _that.$router.replace({
                name: "Myhome",
                query: {
                  shareid: _that.$route.query.shareid,
                  merchid: res.data.data.id
                }
              })
              window.location.reload();
            }
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    /* 重新刷新页面 */
    reloapage() {
      let _that = null;
      _that = this;
      _that.$router.replace({ name: "Myhome", query: {shareid: _that.shareid, merchid: _that.merchid, _t: 3 } });// 成功后重定向，需要去除code参数，防止刷新报错(localStorage['DK_OPENID']控制是否刷新code)
      _that.getinfor();
    },
    /* 如果用户登录中未获的用户openID 保存用户openID */
    saveOpenid() {
      let _that = null;
      _that = this;
      this.loadshow = true;
      let openid = "";
      openid = localStorage.getItem("DK_OPENID");
      if (openid) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/getmidByOpenid", // 查询user_open表有没有mid
            _that.$qs.stringify({
              openid: openid 
            })
          )
          .then(res => {
            console.log(res, "getmidByOpenid");
            if (res.data.code == 100000) { // 有mid 登陆成功
              localStorage.setItem("DK_MEMBERID", res.data.data);
              // 查询会员信息
              _that.$axios
                .post(
                  _that.$store.state.domain + "web/user/get_info_byMid",
                  _that.$qs.stringify({
                    id: res.data.data 
                  })
                )
                .then(response => {
                  if (response.data.code == 100000) {
                    localStorage.setItem("DK_UID",response.data.data);
                    localStorage.setItem("DK_ISLOGIN",true);
                    localStorage.setItem("DK_STRID",response.data.user.strId);
                    _that.loadshow = false;
                    // _that.openidBindUid(); // 绑定用户id
                    _that.reloapage(); // 重新加载页面去除code参数 防止刷新报错
                  }
                })
                .catch(error => {
                  console.log(error);
                })
            }else { // 没有mid
              _that.loadshow = false;
              // 根据openID查询会员信息
              _that.lijidenglu(); // 跳转登陆页面
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
    // 查询上级信息
    getparentInfo() {
      let _that = null;
      _that = this;
      if (parseInt(_that.shareid) > 0) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/getparentByMid",
            _that.$qs.stringify({
              id: _that.shareid
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              _that.agentInfo = res.data.data;
              _that.agentCode = _that.agentInfo.agent_code;
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
    forgetpwd() {
      if (this.tel && this.validator(this.tel)) {
        this.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        this.$axios.post(
          this.$store.state.domain + "web/user/send_sms",
          this.$qs.stringify({
            tel: this.tel,
            type: 'login'
          })
        )
        this.$refs.daojishi.reset();
        this.time = 60 * 1000;
        this.btnDisabled = true;
        this.showTxt = false;

      }else{
        this.$refs.tel.focus();
      }
    },
    // 倒计时完成后执行
    finish() {
      this.btnDisabled = false;
      this.showTxt = true;
    },

    // openid 和用户id绑定 
    openidBindUid() {
      let _that = null;
      _that = this;
      const uid = localStorage.getItem("DK_MEMBERID");
      const openid = localStorage.getItem("DK_OPENID");
      if (parseInt(uid) > 0 && openid) {
        console.log(uid, openid, "setopenid");
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/pay/setopenid",
            _that.$qs.stringify({
              mid: parseInt(uid),
              openid: openid,
              type: "daka"
            })
          )
          .then(res => {
            console.log(res);
            _that.reloapage();
          })
          .catch(err => {
            console.log(err);
          })
      }
    },

    // 设置页面
    settings() {
      if (this.isLogin) {
        this.$router.push({ name: "SetInfo", query: { shareid: this.shareid, merchid: this.merchid } });
      } else {
        this.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
      }
    },
    // 横板页面跳转
    topage(num) {
      if (num == 2) {
        // 收货地址
        if (this.isLogin) {
          this.$router.push({ name: "AddressList", query: { shareid: this.shareid, merchid: this.merchid } });
        } else {
          this.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
        }
      }
    },
    // 退出按钮
    logout() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/logout")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            // localStorage.clear();
            localStorage.removeItem("DK_MERCHID");
            localStorage.removeItem("DK_SHAREID");
            localStorage.removeItem("DA-LANDPAGE-TITLE");
            localStorage.removeItem("searchText");
            localStorage.removeItem("DA-LANDPAGE-REFERRER");
            localStorage.removeItem("DK_STRID");
            localStorage.removeItem("ordersn");
            localStorage.removeItem("orderid");
            localStorage.removeItem("money");
            localStorage.removeItem("DK_DATA");
            localStorage.removeItem("DA-LANDPAGE");
            localStorage.removeItem("DK_WXINFO");
            localStorage.removeItem("DK_OPENID");
            localStorage.removeItem("DK_MEMBERID");
            localStorage.removeItem("DK_UID");
            localStorage.removeItem("DK_ISLOGIN");
            _that.isLogin = false;
            _that.userinfo = {};
            this.$router.go(0);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 查单二维码
    seeOrder() {
      if (this.isLogin) {
        this.$router.push({ name: "SeeOrderQcode", query: { shareid: this.shareid, merchid: this.merchid } });
      } else {
        this.$toast("请登录后查看");
      }
    },

    // 专属客服
    yulankf() {
      console.log("ddd");
      console.log(this.userinfo.kefu_wx);
      if (this.userinfo.kefu_wx) {
        ImagePreview([this.userinfo.kefu_wx]);
      }
    },

    // 显示专属素材号
    onShowsch() {
      this.showsucai = true;
      console.log(this.showsucai);
    },

    yulansch(img) {
      console.log(img);
      ImagePreview([img]);
    },

    // 商户二维码
    shopQr() {
      if (this.isLogin) {
        this.$router.push({ name: "MerchCode", query: { shareid: this.userinfo.id, merchid: this.userinfo.merchid } });
      }
    },
    orderList(num) {
      if (num == 6 || num == 7) {
        this.$router.push({ name: "OrderTwoList", query: { status: num, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
      }else {
        this.$router.push({ name: "OrderList", query: { status: num, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
      }
    },
    // 我的店铺
    myshops() {
      window.location.href="https://shop.midbest.cn/?merchid=" + this.merchid + "&logincode=" + localStorage.getItem("DK_UID");
    },
    // 复制邀请码
    agentCodeCopy() {
      if (this.userinfo.agent_code) {
        let _that = null;
        _that = this;
        _that.$copyText(_that.userinfo.agent_code).then(
          function(e) {
            _that.$toast("复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
      }
    },
    // 消息列表
    setMessage() {
      console.log("消息");
      this.$router.push({ name: "MessageList", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    favorite() {
      this.$router.push({ name: "Favorite", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 马上升级
    mshegnji() {
      let _that = null;
      _that = this;
      if (!this.isLogin) {
        this.$notify({
          type: "warning",
          message: "请登录后再申请"
        })
        if (!this.iswx) {
          setTimeout(() => {
            _that.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
          }, 1000);
        }else {
          // 微信中
          const OPENID = localStorage.getItem("DK_OPENID");
          const WXINFO = localStorage.getItem("DK_WXINFO");
          if(!OPENID) {
            _that.getwxinfo(); // 在微信中打开获取openid或用户信息
          }else if(WXINFO){
            this.loginshow = true;
            _that.getparentInfo();
            setTimeout(() => {
              _that.btnDisabled = true;
            }, 20);
          }
        }
      }else {
        if (this.userinfo.merch_reg.merchtype == 12) {
          _that.$router.push({ name: "merchTishi", query: {pagetitle: "联盟店铺", shareid: _that.shareid, merchid: _that.merchid} });
          return false;
        }
        if (this.userinfo.merch_reg && this.userinfo.merch_reg.lianmeng_id) {
          if (this.userinfo.merch_reg.lianmeng_status == 0) {
            this.$toast("您已经申请联盟店，等待客服审核中");
            return false;
          }
          if (this.userinfo.merch_reg.lianmeng_status == 1) {
            _that.$router.push({ name: "merchTishi", query: {pagetitle: "联盟店铺", shareid: _that.shareid, merchid: _that.merchid} });
            return false;
          }
        }else{
          if (this.userinfo.level1_white == 0) {
            this.$toast("等级不符合要求");
            return false;
          }else{
            // 已经登陆直接进入申请页面
            this.$router.push({ name: "FenxiaoApply1", query: { shareid: this.shareid, merchid: this.merchid } });
          }
        }
      }
    },
    // 申请供货商
    applyGonghuo(){
      let _that = null;
      _that = this;
      if (!this.isLogin) {
        this.$notify({
          type: "warning",
          message: "请登录后再申请"
        })
        if (!this.iswx) {
          setTimeout(() => {
            _that.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
          }, 1000);
        }else {
          // 微信中
          const OPENID = localStorage.getItem("DK_OPENID");
          const WXINFO = localStorage.getItem("DK_WXINFO");
          if(!OPENID) {
            _that.getwxinfo(); // 在微信中打开获取openid或用户信息
          }else if(WXINFO){
            this.loginshow = true;
            _that.getparentInfo();
            setTimeout(() => {
              _that.btnDisabled = true;
            }, 20);
          }
        }
      }else {
        if (this.userinfo.merch_reg && this.userinfo.merch_reg.gonghuo_qianyue) {
          this.$router.push({ name: "GonghuoQianyue", query: {shareid: this.shareid, merchid: this.merchid} });
          return false;
        }
        if (this.userinfo.merch_reg && this.userinfo.merch_reg.gonghuo_bohui) {
          this.$router.push({ name: "GonghuoApply2", query: { shareid: this.shareid, merchid: this.merchid } });
          return false;
        }
        if (this.userinfo.merch_reg && this.userinfo.merch_reg.gonghuo_status == 1) {
          this.$toast("您已经申请供货");
          return false;
        }
        if (this.userinfo.merch_reg.gonghuo_status == 0) {
          this.$toast("您已经申请供货，等待客服审核中");
          return false;
        }else{
          if (this.userinfo.merch_reg.gonghuo_id) {
            // 已驳回进行修改
            this.$router.push({ name: "GonghuoApply2", query: { shareid: this.shareid, merchid: this.merchid } });
          }else{
            // 已经登陆直接进入申请页面
            this.$router.push({ name: "GonghuoApply1", query: { shareid: this.shareid, merchid: this.merchid } });
          } 
        }
      }
    },
    // 登陆
    lijidenglu() {
      
      const OPENID = localStorage.getItem("DK_OPENID");
      if (this.iswx && !OPENID) {
        this.getwxinfo(); // 在微信中打开获取openid或用户信息
      }else{
        this.$router.push({ name: "Login", query: { shareid: this.shareid, merchid: this.merchid } });
      }
      return false;
      
    },
    // 检查手机号绑定的会员id是否绑定openid
    checkMobile() {
      let _that = null;
      _that = this;
      if (_that.tel) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/check_mobile",
            _that.$qs.stringify({
              mobile: _that.tel
            })
          )
          .then(response => {
            console.log(response);
            if (response.data.code == 100000) {
              _that.btnDisabled = false;
            }else {
              _that.$notify({
                type: "warning",
                message: response.data.msg ? response.data.msg : "手机号已使用，请更换手机号",
                duration: 1500
              })
              _that.btnDisabled = true;
            }
          })
          .catch(error => {
            console.log(error);
          })
      } 
    },
    // 历史数据
    goOldshop() {
      window.location.href = "https://mid.midbest.cn/app/index.php?i=1&c=entry&m=ewei_shopv2&do=mobile&r=member&merchid=" + this.merchid;
    },
    // 投诉建议
    complaint() {
      this.$router.push({name: "ComplaintList", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    // 商务合作
    cooperation() {
      this.$router.push({name: "Cooperation", query: { shareid: this.shareid, merchid: this.merchid } });
    },
    chongzhi(name) {
      if (name == "credit2") { // 余额充值
        this.$router.push({name: "Wallet", query: { shareid: this.shareid, merchid: this.merchid } });
      }
    },
    // 分销中心
    fxzhongxin() {
      this.$router.replace({name: "Distributor", query: { shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 子商户绑定会员
    setmerchMber() {
      let _that = null;
      _that = this;
      if (localStorage.getItem("DK_ISLOGIN") && this.merchid > 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID")
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/merch_adduser",
            _that.$qs.stringify({
              merchid: _that.merchid
            })
          )
          .then(res => {
            console.log(res);
          })
          .catch(error => {
            console.log(error);
          })
      }
    },

    // 售后处理
    orderService(shopid) {
      this.$router.push({
        name: "OrderService",
        query: {
          shareid: this.shareid,
          merchid: this.merchid,
          shopid: shopid
        }
      })
    },

    // 添加子账号
    addAccount() {
      this.$router.push({
        name: 'AccountList',
        query: {
          shareid: this.shareid,
          merchid: this.merchid,
        }
      })
    }
  }
};
</script>

<style lang="less">
.myhome {
  overflow-x: hidden;
  position: relative;
  background: #ededed;
  .head {
    background-color: #ed2846;
    .van-cell {
      padding: 10px;
      background-color: #ed2846;
      .nickname {
        flex: 1;
        padding: 0 0 0 10px;
        position: relative;
        text-align: left;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        .nickname_text {
          display: inline-block;
          vertical-align: middle;
        }
        .van-cell {
          padding: 10px 0;

          .van-cell__title {
            color: #fff;
            font-weight: normal;
          }
          .van-icon {
            color: #fff;
          }
        }
        .van-tag {
          font-size: 10px;
          padding: 0 3px;
          margin-left: 5px;
          vertical-align: middle;
          height: 14px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
        }
      }
      .van-cell__label {
        .van-cell {
          padding: 0;
          color: #fff;
          font-size: 12px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
          .van-cell__title {
            flex: 1;
            line-height: 24px;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
            .van-tag {
              background-color: rgba(255, 0, 0, 0);
              border-color: #fff;
              color: #fff;
              margin-left: 3px;
              padding: 0 4px;
              font-size: 10px;
            }
          }
        }
      }
      .right_icon {
        color: #fff;
        font-size: 24px;
        margin-right: 20px;
        &:last-of-type {
          margin-right: 10px;
        }
      }
    }
    .van-grid {
      color: #fff;
      .van-grid-item__content {
        padding: 0 10px 10px;
        background-color: #ed2846;
        font-size: 12px;
        .van-info {
          font-size: 10px;
          background-color: rgba(255, 0, 0, 0.2);
        }

        .van-grid-item-text {
          font-size: 12px;
          -webkit-box-align: center;
          -webkit-align-items: center;
          align-items: center;
        }
      }
      .van-grid-item__text {
        color: #fff;
        margin-top: 5px;
      }
    }
    .shengji {
      padding: 0 10px;
      overflow: hidden;
      .van-cell {
        padding: 10px;
        background-color: #efddb6;
        border-radius: 10px 10px 0 0;
        .van-cell__title {
          color: #944604;
          font-size: 14px;
          font-weight: bold;
        }
        .van-cell__value {
          flex: 1;
          padding: 0 10px;
          text-align: left;
          color: #914602;
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .van-tag {
          padding: 0 10px;
          background-color: #fff;
          font-size: 12px;
          color: #914602;
        }
      }
    }
  }

  // 等级升级
  .level_info {
    margin: 0 10px 10px;
    overflow: hidden;
    border-radius: 0 0 10px 10px;
    .van-cell {
      padding: 10px 16px;
      font-size: 12px;
      .van-cell__title {
        .level_title {
          font-size: 12px;
        }
        .van-hairline--right {
          padding: 6px 16px 6px 0;
          line-height: 24px;
          font-size: 14px;
          min-width: 60px;
          &::after {
            border-right-width: 2px;
            border-right-color: #333;
          }
        }
        .level_text {
          .van-tag {
            padding: 2px 10px;
          }
        }
      }
      .van-cell__value {
        flex: 1;
        text-align: center;
        overflow: hidden;
        line-height: 20px;
        color: #333;
        .title_text {
          color: #754A20;
          span {
            font-weight: bold;
            margin-left: 2px;
          }
        }
        .van-cell {
          font-size: 12px;
          padding: 5px 0 0 16px;
          line-height: 20px;
          &:first-of-type {
            padding-top: 0;
          }
          .van-cell__title {
            line-height: 20px;
          }
          .van-cell__value {
            padding: 0 10px;
            flex: 1;
            text-align: center;
            overflow: hidden;
            height: 20px;
            line-height: 20px;
            color: #333;
            .van-progress {
              margin-top: 8px;
            }
          }
          .right_icon_text {
            min-width: 40px;
            text-align: center;
          }
        }
      }
    }
    .miankaohe {
      .van-cell__title {
        flex: 1;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: #323233;
      }
    }
  }
  // 按钮列表
  .menu_list {
    padding: 0 10px 10px;
    margin-top: 10px;
    .van-grid {
      border-radius: 10px;
      overflow: hidden;
      background-color: #fff;
    }
    .van-grid-item {
      overflow: hidden;
      .van-grid-item__text {
        overflow: hidden;
      }
    }
    .van-grid-item__icon,
    .van-grid-item__text {
      white-space: nowrap;
    }
  }
  // 广告图
  .ad {
    padding: 0 10px 10px;
    overflow: hidden;
    .van-image {
      width: 100%;
      border-radius: 10px;
      overflow: hidden;
      display: block;
      img {
        display: block;
      }
    }
  }
  // 横版按钮
  .tiaozhuan_list {
    padding: 0 10px 10px;
    overflow: hidden;
    .van-cell-group {
      border-radius: 10px;
      overflow: hidden;
      .van-cell {
        padding: 10px;
        .van-cell__title {
          flex: 1;
          text-align: left;
          padding-left: 5px;
          height: 30px;
          line-height: 30px;
        }
        .van-icon {
          line-height: 30px;
        }
        &:last-of-type::after {
          border: 0;
        }
      }
    }
  }
  .btnlogin {
    margin-top: 10px;
    padding: 20px;
  }
  .form{
    padding: 0 16px;
    .van-cell__title{
      width: 60px;
      text-align: left;
    }
    .van-cell__value{
      flex: 1;
      text-align: left;
    }
  }
  .seconds{
    color: #fff;
  }
  // 加载中
  #loading {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;
      padding: 20px;
      border-radius: 4px;
      color: #fff;
      .van-loading__text{
        color: #fff;
      }
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .toxcx {
    width: 100px;
    padding: 20px;
    line-height: 24px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    position: fixed;
    top: 20%;
    left: 50%;
    margin-left: -70px;
    z-index: 11;
  }
  .back_zhezhao {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  /* 素材号 Send */
  .sucai_show {
    overflow: hidden;
    text-align: center;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .sucai_show_title {
    line-height: 24px;
  }
  .sucai_show_tishi {
    line-height: 24px;
    font-size: 10px;
    margin-bottom: 5px;
  }
  /* 素材号 End */
}
</style>
